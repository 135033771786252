import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.tooltip = new Tooltip(this.element);
  }

  async copyToClipboard(e) {
    try {
      await navigator.clipboard.writeText(e.currentTarget.dataset.url);
      this.tooltip.setContent({ '.tooltip-inner': 'Link Copied To Clipboard' })
    } catch (err) {
      this.tooltip.setContent({ '.tooltip-inner': 'Failed to copy' })
    }
  }

  setTooltipText() {
    this.tooltip.setContent({ '.tooltip-inner': 'Copy Link' })
  }
}
