import { Autocomplete } from "stimulus-autocomplete"

export default class BulkEditAutocompleteController extends Autocomplete {
  // Auto-select first element in the list
  replaceResults(html) {
    this.resultsTarget.innerHTML = html
    this.identifyOptions()

    if (!!this.options && this.options.length > 0) {
      this.open()
      this.select(this.options[0]);
    } else {
      this.close()
    }
  }

  onArrowDownKeydown = (event) => {
    const item = this.sibling(true)
    if (item) {
      this.select(item)
      this.commit(item);
    }
    event.preventDefault()
  }

  onArrowUpKeydown = (event) => {
    const item = this.sibling(false)
    if (item)  {
      this.select(item)
      this.commit(item);
    }
    event.preventDefault()
  }

  onEnterKeydown = (event) => {
    if (!this.inputTarget?.value) return

    const selected = this.selectedOption
    if (selected && this.resultsShown) {
      this.commit(selected)

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        document.querySelector("form[data-bulk-edit-target='searchForm']").requestSubmit()
      }, 200)

      if (!this.hasSubmitOnEnterValue) {
        event.preventDefault()
      }
    }
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim()
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected }
      })
    )
  }
}
