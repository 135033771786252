import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["taskSubmitWarningModal"];

  connect() {
    this.urlHash = window.location.hash;
    this.documentRequiredWarningModal = new Modal(this.taskSubmitWarningModalTarget);
    if (this.urlHash === "#documentation_required") {
      this.documentRequiredWarningModal.show()
    }
  }
}
