import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="readiness_officess"
export default class extends Controller {
  static targets = ['taskForm', 'editOffcanvas'];

  connect() {
    this.form = this.taskFormTarget;

    const csrfToken = document.head.querySelector("[name='csrf-token']").content;
    this.form.elements[0].value = csrfToken;

    this.editOffcanvasTarget.addEventListener('hidden.bs.offcanvas', () => {
      this.clearFormContent();
    });
  }

  editBtnClick(e) {
    const taskData = e.currentTarget.dataset.task;
    const task = JSON.parse(taskData);

    this.updateFormContent(task);
  }

  updateFormContent(task) {
    const formatDateToYYYYMMDD = (date) => {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }

    this.form.action = `/tasks/${task.id}`

    this.form.querySelector("input[id='office_id']").value = task.office_id;
    this.form.querySelector("p[id='office_name']").textContent = task.office.name;
    this.form.querySelector("p[id='requirement_framework_category_name']").textContent = task.office_requirement.framework_category.name;
    this.form.querySelector("input[id='due_date']").value = formatDateToYYYYMMDD(task.due_date);
    this.form.querySelector("select[id='dismissal_reason']").value = task.dismissal_reason || "";

    if (task.owner_id == null) {
      this.form.querySelector("select[id='owner_id']").value = this.form.querySelector("select[id='owner_id']").options[0]?.value;
    } else {
      this.form.querySelector("select[id='owner_id']").value = task.owner_id;
    }

    this.form.querySelector("textarea[id='name']").value = task.name;

    const statusRadios = this.form.querySelectorAll("input[data-status]");
    [...statusRadios].forEach(el => {
      if (task.status == el.dataset.status) {
        el.checked = true;
      }
    });
  }

  clearFormContent() {
    this.form.querySelector("input[id='office_id']").value = '';
    this.form.querySelector("p[id='office_name']").textContent = '';
    this.form.querySelector("p[id='requirement_framework_category_name']").textContent = '';

    this.form.querySelector("select[id='owner_id']").value = this.form.querySelector("select[id='owner_id']").options[0]?.value;
    this.form.querySelector("textarea[id='name']").value = '';

    const statusRadios = this.form.querySelectorAll("input[data-status]");
    [...statusRadios].forEach(el => {
      el.checked = false;
    });
  }
}
