import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scrollBtnDiv"];

  connect() {
    const screenHeight = screen.height;
    const mainScreenHeight = document.querySelector('.main-content').offsetHeight;
    if (mainScreenHeight < screenHeight) {
      this.scrollBtnDivTarget.classList.add("d-none");
    } else {
      this.scrollBtnDivTarget.classList.remove("d-none");
    }
  }

  scrollToBottom() {
    document.querySelector('.main-content').scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  scrollToTop() {
    document.querySelector('.main-content').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
