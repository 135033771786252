import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = ["agreedToTOSButton"];

  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  scroll(e) {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isAtBottom = scrollTop + clientHeight + 2 >= scrollHeight;

    if (isAtBottom) {
      this.agreedToTOSButtonTarget.disabled = false;
    }
  }

  async agreedToTOS(e) {
    const userId = e.target.dataset.userId;

    try {
      const request = new FetchRequest('put', `/users/${userId}/agree_to_tos`)
      const response = await request.perform();
      if (response.ok) {
        this.modal.hide();
      }
    } catch (err) {
      this.modal.hide();
      alert('Oops, something went wrong');
    }
  }
}
