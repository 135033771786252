import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="office-checklist"
export default class extends Controller {
  static targets = ['subtasksToggleCollapseButton', 'listItemCheckbox', 'compliantRadioButton', 'radioButton']

  checkAll() {
    this.compliantRadioButtonTargets.forEach(item => {
      item.checked = true;
    });

    this.listItemCheckboxTargets.forEach(item => {
      item.checked = true
    });
  }

  uncheckAll() {
    this.radioButtonTargets.forEach(item => {
      item.checked = false;
    });

    this.listItemCheckboxTargets.forEach(item => {
      item.checked = false
    });
  }

  toggleCollapse() {
    if (this.subtasksToggleCollapseButtonTarget.classList.contains('collapsed')) {
      this.subtasksToggleCollapseButtonTarget.textContent = 'Click to expand';
    } else {
      this.subtasksToggleCollapseButtonTarget.textContent = 'Click to collapse';
    }
  }
}
