import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

export default class extends Controller {
  connect() {
    let editElement = document.querySelectorAll("select.offices-select")
    for (let i = 0; i < editElement.length; i++) {
      new Choices(editElement[i], {
        allowHTML: true,
        removeItemButton: true
      })
    }
  }
}

