import { Controller } from "@hotwired/stimulus"
import { Offcanvas } from "bootstrap";

// Connects to data-controller="requirements_offices"
export default class extends Controller {
  static targets = [
    'editRequirementOffcanvas',
    'requirementForm',
  ];

  connect() {
    // requirement edit form
    this.requirementForm = this.requirementFormTargets[0];
    this.addRequirementForm = this.requirementFormTargets[1];

    this.requirementForm.elements[0].value = document.head.querySelector("[name='csrf-token']").content;
    this.addRequirementForm.elements[0].value = document.head.querySelector("[name='csrf-token']").content;

    this.offcanvas = new Offcanvas(this.editRequirementOffcanvasTarget);

    this.editRequirementOffcanvasTarget.addEventListener('hidden.bs.offcanvas', () => {
      this.clearFormContent();
    });
  }

  // Open edit requirement form
  editBtnClick(e) {
    const rowData = e.currentTarget;
    const requirement = JSON.parse(rowData.dataset.requirement);

    const assgineeSelectOptions = JSON.parse(rowData.dataset.assignees) || [];

    this.requirementForm.dataset.requirementId = requirement.id;

    this.officeName = this.requirementForm.querySelector("p[id='office_requirement_office_name']");
    this.categorySelect = this.requirementForm.querySelector("select[id='framework_category_id']");
    this.nameTextarea = this.requirementForm.querySelector("textarea[id='name']");
    // this.freqUnitSelect = this.requirementForm.querySelector("select[id='frequency_unit']");
    this.documentRequiredText = this.requirementForm.querySelector("span[id='documentation_required']");

    // this.freqStartSelect = this.requirementForm.querySelector("select[id='frequency_start']");
    // this.freqCustomTimelineInput = this.requirementForm.querySelector("input[id='frequency_custom_timeline']");

    this.assigneeSelect = this.requirementForm.querySelector("select[id='user_id']");
    assgineeSelectOptions.forEach((option, index) => {
      const text = option["first_name"] + " " + option["last_name"];
      const value = option["id"];
      this.assigneeSelect.options[index] = new Option(text, value);
    });

    this.linkToInstructionsInput = this.requirementForm.querySelector("input[id='link_to_instructions']");
    this.primaryLinkToDocumentsInput = this.requirementForm.querySelector("input[id='primary_link_to_documents']");

    this.officeId = e.target.dataset.officeId;

    this.requirementForm.querySelector("input[id='office_id']").value = office_id;
    this.updateRequirementFormContent(requirement);

    this.offcanvas.show();
  }

  // Open add requirement form
  addBtnClick(e) {
    this.addRequirementForm.action = `/offices/${e.currentTarget.dataset.officeId}/office_requirements/`;
    this.addRequirementForm.elements[1].value = 'post';

    const officeNameField = this.addRequirementForm.querySelector("p[id='office_requirement_office_name']");
    officeNameField.textContent = e.currentTarget.dataset.officeName;

    const assgineeSelectOptions = JSON.parse(e.currentTarget.dataset.assignees);
    const assigneeSelect = this.addRequirementForm.querySelector("select[id='user_id']");
    assigneeSelect.options.length = assgineeSelectOptions.length;

    assgineeSelectOptions.forEach((option, index) => {
      const text = option["first_name"] + " " + option["last_name"];
      const value = option["id"];
      assigneeSelect.options[index] = new Option(text, value);
    });

    this.addRequirementForm.querySelector("input[id='office_id']").value = e.currentTarget.dataset.officeId;
  }

  updateRequirementFormContent(requirement) {
    const {
      office_id,
      framework_category_id,
      name,
      frequency_unit,
      // frequency_start,
      // frequency_custom_timeline,
      user_id,
      link_to_instructions,
      primary_link_to_documents,
      documentation_required,
    } = requirement;

    this.requirementForm.action = `/offices/${office_id}/office_requirements/${requirement.id}`;
    this.requirementForm.elements[1].value = 'put';

    this.officeName.textContent = requirement.office.name
    this.requirementForm.querySelector("input[id='office_id']").value = office_id;
    this.categorySelect.value = framework_category_id;
    this.nameTextarea.value = name;
    // this.freqUnitSelect.value = frequency_unit;
    // this.freqStartSelect.value = frequency_start;
    // this.freqCustomTimelineInput.value = frequency_custom_timeline;
    this.assigneeSelect.value = user_id;
    this.linkToInstructionsInput.value = !link_to_instructions ? '' : link_to_instructions;
    this.primaryLinkToDocumentsInput.value = !primary_link_to_documents ? '' : primary_link_to_documents;
    this.documentRequiredText.textContent = documentation_required ? 'Yes' : 'No';
  }

  clearFormContent() {
    this.categorySelect.value = this.categorySelect.options[0].value;
    this.nameTextarea.value = '';
    // this.freqStartSelect.value = this.freqStartSelect.options[0].value;;
    // this.freqUnitSelect.value = this.freqUnitSelect.options[0].value;
    // this.freqCustomTimelineInput.value = '';
    this.assigneeSelect.options.length = 0;
    this.linkToInstructionsInput.value = '';
    this.primaryLinkToDocumentsInput.value = '';
    this.documentRequiredText.textContent = 'No';
  }
}
