import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  sort(e) {
    const currentUrl = new URL(window.location.href);
    const paramValue = e.currentTarget.value.split('__');

    const sortBy = paramValue[0];
    const dueDate = paramValue[1];

    currentUrl.searchParams.set('sort_by', sortBy);
    currentUrl.searchParams.set('type', dueDate);

    Turbo.visit(currentUrl.href);
  }
}
