import { Controller } from "@hotwired/stimulus"
import { tableRowHighlightOnCheckboxChange } from "../utils/tableRowHighlightOnCheckboxChange"

export default class extends Controller {
  onRowClick(e) {
    const row = this.element;

    if (e.target.tagName === "INPUT") {
      tableRowHighlightOnCheckboxChange(row, 'row__highlight', e.target.checked);
      return;
    }

    const checkbox = e.currentTarget.querySelector('input');
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
      tableRowHighlightOnCheckboxChange(row, 'row__highlight', checkbox.checked);
    }
  }
}
