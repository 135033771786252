import { Controller } from "@hotwired/stimulus"
import { Offcanvas } from "bootstrap"
import Choices from "choices.js";

export default class extends Controller {
  static targets = ['editMemberOffcanvas'];

  connect() {
    this.editOffcanvas = new Offcanvas(this.editMemberOffcanvasTarget);
    this.editForm = this.editMemberOffcanvasTarget.querySelector('form');
  }

  openEditOffcanvas(e) {
    if (this.officeChoices) {
      this.officeChoices.destroy();
    }

    this.setEditFormData(e);
    this.editOffcanvas.show();
  }

  setEditFormData(e) {
    const dataset = e.currentTarget.dataset;
    const providerData = JSON.parse(dataset.provider);

    const {
      id,
      first_name,
      last_name,
      primary_email,
      primary_mobile,
      primary_state,
      personnel_role_id,
      office_ids,
    } = providerData;

    this.editForm.action = `/people/${id}`;

    Object.entries(providerData)
      .filter(([key, _]) => key !== 'office_ids' && key !== 'id')
      .forEach(([key, value]) => {
        this.editForm.querySelector(`#person_${key}`).value = value;
      });

    const officeSelect = this.editForm.querySelector('#offices');
    const options = officeSelect.querySelectorAll('option');

    options.forEach((option) => {
      option.selected = office_ids.includes(+option.value);
    });

    this.officeChoices = new Choices(officeSelect, {
        allowHTML: true,
        removeItemButton: true
    });
  }
}
