import { Controller } from "@hotwired/stimulus"
import { pluralize } from "../utils/pluralize";

export default class extends Controller {
  static targets = ['filesInput', 'filesPreview'];

  onFilesChange(e) {
    const files = e.target.files;
    this.setFilesPreview(files);
  }

  setFilesPreview(files) {
    if (files.length === 0 || files.length === 1) {
      this.filesPreviewTarget.style.marginTop = '';
      this.filesPreviewTarget.textContent = '';
      return;
    }

    if (files.length > 1) {
      const restCount = files.length - 1;
      this.filesPreviewTarget.style.marginTop = '0.75rem';
      this.filesPreviewTarget.textContent = `${files[0].name} & ${pluralize(restCount, 'other file')}`;
    }
  }
}
