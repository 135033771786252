import { Controller } from '@hotwired/stimulus';
import Dropzone from 'dropzone';

export default class extends Controller {

  connect() {
    Dropzone.autoDiscover = false;

    this.dropzone = new Dropzone(this.element, {
      paramName: "file",
      maxFilesize: 100,
      addRemoveLinks: true,
      dictRemoveFile: "Remove",

      autoProcessQueue: true,
      uploadMultiple: false,
      parallelUploads: 100,
      maxFiles: 100,

      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },

      acceptedFiles: ".pdf, .jpeg, .jpg, .png, .heic, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx",

      init: function() {
        var myDropzone = this;

        this.on("addedfile", (file) => {
          this.element.querySelector('.dz-message').style.opacity = '0'
          file.previewElement.querySelector('.dz-progress').style.top = '65%';
        });

        this.on("removedfile", _ => {
          if (this.files.length === 0) {
            document.querySelector('.dz-message').style.opacity = '1';
          }

          const hasErrorFiles = this.files.some((f) => f.status === "error");
          if (hasErrorFiles && this.files.length === 0) {
            this.element.querySelector('.dz-message').style.opacity = '0';
          }
        });

        this.on("queuecomplete", (file) => {
          const hasErrorFiles = this.files.some((f) => f.status === "error");
          if (hasErrorFiles) {
            this.element.querySelector('.dz-message').style.opacity = '0';
          } else {
            this.element.querySelector('.dz-message').style.opacity = '1';
          }
        });

        this.on("success", (file, response) => {
          const wrapper = document.querySelector('#files__container__wrapper');
          if (wrapper)  {
            wrapper.classList.remove('d-none');
          }

          file.previewElement.parentNode.removeChild(file.previewElement);
          Turbo.renderStreamMessage(response);
          this.removeFile(file);
        });

        this.on("error", (file, err) => {
          this.element.querySelector('.dz-message').style.opacity = '0'
          file.previewElement.querySelector('.dz-image').classList.add('border', 'border-danger');
          file.previewElement.querySelector('.dz-error-message').style.top = '150px';

          if (err.message) {
            file.previewElement.querySelector('.dz-error-message').textContent = err.message;
            return;
          }

          if (err.includes("File is too big")) {
            const fileSizeInMB = file.size / (1000 * 1000);
            const errMsg = "The file size is " + Math.round(fileSizeInMB) + " MB. The maximum file size is " + this.options.maxFilesize + " MB.";
            file.previewElement.querySelector('.dz-error-message').textContent = errMsg;
            return;
          }
        });

      }
    });
  }

}
