// app/javascript/controllers/image_viewer_controller.js
import { Controller } from "@hotwired/stimulus"
import Viewer from "viewerjs";

export default class extends Controller {
  connect() {
    new Viewer(this.element, {
      toolbar: { zoomIn: true, zoomOut: true, rotateLeft: true, rotateRight: true },
    }).show();
  }
}

