import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task-note-form"
export default class extends Controller {
    static targets = ['noteForm'];

    connect() {
        this.form = (this.noteFormTarget);
        this.form.elements[1].value = document.head.querySelector("[name='csrf-token']").content;;
    }

    openNoteForm(e) {
        const {
            id,
            name,
            status,
            documents,
            linkedFile,
            instructions,
            officeName,
            snoozeUntil,
            reasonForSnooze,
            updatedAt,
            dismissReason,
            active,
            brandName,
            makeModel,
            serialNumber,
        } = e.currentTarget.dataset;

        this.form.action = `/tasks/${id}/`;

        const taskNameField = this.form.querySelector("p[id='task_name']");
        const officeNameField = this.form.querySelector("p[id='office_name']");
        const taskIdField = this.form.querySelector("input[id='task_id']");
        const linkedFileField = this.form.querySelector("input[id='task_primary_link_to_file']");
        const instructionsLinkField = this.form.querySelector("a[id='link_to_instructions']");
        const documentsLinkField = this.form.querySelector("a[id='primary_link_to_document']");
        const snoozeUntilInput = this.form.querySelector("input[id='task_snooze_until']");
        const reasonForSnoozeInput = this.form.querySelector("select[id='task_reason_for_snooze']");
        const updatedAtField = this.form.querySelector("#task_updated_at");
        const dismissalReasonSelect = this.form.querySelector("#task_dismissal_reason");
        const brandNameField = this.form.querySelector("#task_brand_name");
        const makeModelField = this.form.querySelector("#task_make_model");
        const serialNumberField = this.form.querySelector("#task_serial_number");
        let activeField = this.form.querySelector("#active");

        taskNameField.textContent = name;
        officeNameField.textContent = officeName;

        taskIdField.value = id;
        activeField.value = active;

        instructionsLinkField.textContent = instructions;
        instructionsLinkField.href = instructions;

        documentsLinkField.textContent = documents;
        documentsLinkField.href = documents;

        linkedFileField.value = linkedFile;

        // if (snoozeUntil) {
        //     const dateObject = new Date(snoozeUntil);
        //     const options = {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit'
        //     };
        //
        //     const formattedDate = dateObject.toLocaleDateString('en-US', options);
        //     const [month, day, year] = formattedDate.split('/');
        //     const value = `${year}-${month}-${day}`
        //     snoozeUntilInput.value = value;
        // } else {
        //     snoozeUntilInput.value = "";
        // }

        makeModelField.value = makeModel;
        brandNameField.value = brandName;
        serialNumberField.value = serialNumber;

        if (dismissalReasonSelect) {
            dismissalReasonSelect.value = dismissReason;
        } else {
            dismissalReasonSelect.value = "";
        }

        if (updatedAt) {
            const dateObject = new Date(updatedAt);
            const options = {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            };
            const formattedDate = dateObject.toLocaleDateString('en-US', options);
            updatedAtField.textContent = formattedDate;
        } else {
            updatedAtField.textContent = "";
        }

        if (status) {
            this.form.querySelector(`input[data-status='pending']`).removeAttribute("checked");
            this.form.querySelector(`input[data-status=${status}]`).setAttribute("checked", "checked");
        } else {
            this.form.querySelector(`input[data-status='pending']`).setAttribute("checked", "checked");
        }

        let updateStatusRadio = this.form.querySelectorAll('input[name="task[status]"]:checked');
        if (updateStatusRadio[0].value != 'dismissed'){
            document.getElementById("task_dismissal_reason").setAttribute("disabled", true)
        } else {
            document.getElementById("task_dismissal_reason").removeAttribute("disabled")
        }

        // reasonForSnoozeInput.value = reasonForSnooze;
    }
}
