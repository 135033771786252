import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { pluralize } from "../utils/pluralize";

export default class extends Controller {
  static targets = ['submitModal', 'submitConfirmationModal', 'taskCheckbox', 'fileInput', 'filesPreview', 'errorMessage'];

  formStatus = null
  noteContent = ""
  hasDocumentationRequired = null

  connect() {
    this.submitModal = new Modal(this.submitModalTarget);
    this.submitConfirmationModal = new Modal(this.submitConfirmationModalTarget);
    this.submitForm = this.submitModalTarget.querySelector('form');
    this.submitButton = this.submitForm.querySelector('#submit-button');
    this.noteField = this.submitForm.querySelector('input[type="hidden"][name="note"]');
    this.statusRadios = this.submitForm.querySelectorAll('input[type="radio"][name="status"]');
  }

  openSubmitModal() {
    this.checkedCheckboxes = this.taskCheckboxTargets.filter((checkbox) => {
      return checkbox.checked
    });

    this.hasDocumentationRequired = this.checkedCheckboxes.filter((checkbox) => {
      return checkbox.dataset.documentationRequired === 'true'
    });

    this.setTaskCount(this.checkedCheckboxes.length);

    this.validateSubmission();

    this.submitModal.show();
  }

  onFilesChange(e) {
    const files = e.target.files;
    this.setFilesPreview(files);
    this.validateSubmission();
  }

  onStatusChange(e) {
    const val = e.target.value;
    this.formStatus = val;
    this.validateSubmission();
  }

  setFilesPreview(files) {
    if (files.length === 0 || files.length === 1) {
      this.filesPreviewTarget.style.marginTop = '';
      this.filesPreviewTarget.textContent = '';
      return;
    }

    if (files.length > 1) {
      const restCount = files.length - 1;
      this.filesPreviewTarget.style.marginTop = '0.75rem';
      this.filesPreviewTarget.textContent = `${files[0].name} & ${pluralize(restCount, 'other file')}`;
    }
  }

  setTaskCount(taskCount) {
    const taskCountField = this.submitModalTarget.querySelector('#task-count');
    taskCountField.textContent = `${pluralize(taskCount, 'Task')} Selected`;
  }

  validateSubmission() {
    if (this.checkedCheckboxes.length === 0) {
      this.submitButton.disabled = true;
      return;
    }

    if (
      this.formStatus === null &&
      this.fileInputTarget.files.length === 0 &&
      this.noteContent === ""
    ) {
      this.submitButton.disabled = true;
      return;
    }

    if (
      this.formStatus === 'compliant' &&
      this.hasDocumentationRequired.length > 0 &&
      this.fileInputTarget.files.length === 0
    ) {
      this.submitButton.disabled = true;
      this.errorMessageTarget.textContent = `You have selected ${this.hasDocumentationRequired.length} task(s) that require documentation. Please select one or more files to upload.`;
      return;
    }

    this.submitButton.disabled = false;
    this.errorMessageTarget.textContent = '';
  }

  openSubmitConfirmationModal() {
    this.submitModal.hide();
    this.submitConfirmationModal.show();

    const note = this.submitConfirmationModalTarget.querySelector('#documentation-required-note');
    if (!note) return;

    if (this.fileInputTarget.files.length > 0) {
      note.classList.remove('d-none');
    } else {
      note.classList.add('d-none');
    }
  }

  onNoteChange(e) {
    this.noteContent = e.target.value;
    this.validateSubmission();
  }
}

