import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dismissalReasonSelectWrapper']

  toggleDismissalReasonSelect(e) {
    if (e.currentTarget.value === 'dismissed') {
      this.dismissalReasonSelectWrapperTarget.classList.remove('d-none');
    } else {
      this.dismissalReasonSelectWrapperTarget.classList.add('d-none');
    }
  }

  checkDismissed(e) {
    if (e.target.value != 'dismissed'){
      document.getElementById("task_dismissal_reason").setAttribute("disabled", true)
    } else {
      document.getElementById("task_dismissal_reason").removeAttribute("disabled")
    }
  }
}
