import { Controller } from '@hotwired/stimulus';
import Dropzone from 'dropzone';

export default class extends Controller {
  connect() {
    Dropzone.autoDiscover = false;

    this.dropzone = new Dropzone(this.element, {
      paramName: "file",
      maxFilesize: 100,
      addRemoveLinks: true,
      dictRemoveFile: "Remove",

      autoProcessQueue: false,
      uploadMultiple: false,
      maxFiles: 1,

      acceptedFiles: ".pdf, .jpeg, .jpg, .png, .heic, .doc, .docx, .txt, .csv, .xls, .xlsx, .ppt, .pptx",

      init: function() {
        var myDropzone = this;

        document.querySelector("button[type=submit]").addEventListener("click", function(e) {
          e.preventDefault();
          e.stopPropagation();
          myDropzone.processQueue();
        });

        this.on("success", (file, response) => {
          Turbo.visit(response.redirect_path);
        });

        this.on("maxfilesexceeded", function(file){
          alert("You can only upload one file at a time");
          file.previewElement.parentNode.removeChild(file.previewElement);
        });

        this.on("addedfile", (file) => {
          this.element.querySelector('.dz-message').classList.add("opacity-0");
          file.previewElement.querySelector('.dz-progress').style.top = '65%';
        });

        this.on("removedfile", _ => {
          this.element.querySelector('.dz-message').classList.remove("opacity-0");
          document.querySelector('.dz-message').style.opacity = '1';
        });

        this.on("queuecomplete", (file) => {
          this.element.querySelector('.dz-message').style.opacity = '1';
        });

        this.on("error", (file, err) => {
          this.element.querySelector('.dz-message').style.opacity = '0'
          file.previewElement.querySelector('.dz-image').classList.add('border', 'border-danger');
          file.previewElement.querySelector('.dz-error-message').style.top = '150px';

          if (err.message) {
            file.previewElement.querySelector('.dz-error-message').textContent = err.message;
            return;
          }

          if (err.includes("File is too big")) {
            const fileSizeInMB = file.size / (1000 * 1000);
            const errMsg = "The file size is " + Math.round(fileSizeInMB) + " MB. The maximum file size is " + this.options.maxFilesize + " MB.";
            file.previewElement.querySelector('.dz-error-message').textContent = errMsg;
            return;
          }
        });

      }
    });
  }

}
