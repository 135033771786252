import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editModal", "editForm"];

    connect() {
        this.form = this.editFormTarget;
        this.form.elements[1].value = document.head.querySelector("[name='csrf-token']").content;
    }

    openEditFormModal(e) {
        const {
            categoryId,
            categoryName,
            categoryAccount,
        } = e.currentTarget.dataset;

        this.form.action = `/frameworks/${categoryId}`

        const deleteCategoryBtn = this.form.querySelector("a[id='delete-category-btn']");
        deleteCategoryBtn.href = `/frameworks/${categoryId}`

        const idTextField = this.form.querySelector("input[id='framework_category_id']");
        const accountTextField = this.form.querySelector("p[id='framework_category_account_name']");
        const nameTextArea = this.form.querySelector("textarea[id='framework_category_name']");

        idTextField.value = categoryId;
        accountTextField.value = categoryAccount;
        nameTextArea.value = categoryName;
    }
}
