import { Controller } from "@hotwired/stimulus"
import { tableRowHighlightOnCheckboxChange } from "../utils/tableRowHighlightOnCheckboxChange"

// data-controller='bulk-edit'
export default class extends Controller {
  static targets = ["searchForm", "tableRow"];

  searchOptionRequestOnClick() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.searchFormTarget.requestSubmit()
    }, 200)
  }

  checkAllRequirements(e) {
    const selectAllCheckbox = e.currentTarget;
    const table = selectAllCheckbox.closest("table");
    [...table.querySelectorAll(".card input[type='checkbox']")].forEach(element => {
      element.checked = true;
    });
  }

  uncheckAllRequirements(e) {
    const selectAllCheckbox = e.currentTarget;
    const table = selectAllCheckbox.closest("table");
    [...table.querySelectorAll(".card input[type='checkbox']")].forEach(element => {
      element.checked = false;
    });
  }

  checkAllToggle(event) {
    if (event.currentTarget.checked) {
      this.checkAllRequirements(event);
      this.tableRowTargets.forEach(row => {
        tableRowHighlightOnCheckboxChange(row, 'row__highlight', true);
      });
    } else {
      this.uncheckAllRequirements(event);
      this.tableRowTargets.forEach(row => {
        tableRowHighlightOnCheckboxChange(row, 'row__highlight', false);
      });
    }
  }

  // Holding shift key to select multiple rows =========================================================================
  lastRowChecked = null;
  onRowClick(e) {
    if (e.shiftKey && this.lastRowChecked) {
      let start = this.tableRowTargets.indexOf(e.currentTarget);
      let end = this.tableRowTargets.indexOf(this.lastRowChecked);

      if (start > end) {
        [start, end] = [end, start];
      }

      const checked = e.currentTarget.querySelector('input[type="checkbox"]').checked;

      for (let i = start; i <= end; i++) {
        const row = this.tableRowTargets[i];
        const checkbox = this.tableRowTargets[i].querySelector('input[type="checkbox"]');
        checkbox.checked = checked;
        tableRowHighlightOnCheckboxChange(row, 'row__highlight', checked);
      }
    }

    this.lastRowChecked = e.currentTarget;
  }
}
