import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const closeButton = document.getElementById('close-button');
    if (closeButton) {
      closeButton.onclick = () => {
        document.getElementById('mobileUserAlertModal').classList.add('mobile-checked')
        document.getElementById('mobileUserAlertModal').classList.remove('mobile-unchecked')
      }
    }
  }
}
