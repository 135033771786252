import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ['editRequirementModal', 'deleteRequirementConfirmationModal'];

  requirementId = null;
  requirementName = null;

  connect() {
    this.editRequirementModal = new Modal(this.editRequirementModalTarget);
    this.editRequirementForm = this.editRequirementModalTarget.querySelector('form');
    this.deleteRequirementConfirmationModal = new Modal(this.deleteRequirementConfirmationModalTarget);
    this.deleteRequirementForm = this.deleteRequirementConfirmationModalTarget.querySelector('form');
  }

  openEditRequirementModal(e) {
    this.setEditRequirementFormData(e);
    this.editRequirementModal.show();
  }

  openDeleteRequiremenConfirmationtModal() {
    this.editRequirementModal.hide();
    this.setDeleteRequirementFormData();
    this.deleteRequirementConfirmationModal.show();
  }

  setEditRequirementFormData(e) {
    const dataset = e.currentTarget.dataset;
    const requirementData = JSON.parse(dataset.requirement);

    const {
      id,
      link_to_instructions,
      name,
      record_type,
      documentation_required,
      personnel_role_id,
    } = requirementData;

    this.editRequirementForm.action = `/personnel_requirements/${id}`

    Object.entries(requirementData)
      .filter(([key, _]) => key !== 'id')
      .forEach(([key, value]) => {
        this.editRequirementForm.querySelector(`#personnel_requirement_${key}`).value = value;
      });

    this.requirementId = id;
    this.requirementName = name;
  }

  setDeleteRequirementFormData() {
    this.deleteRequirementForm.action = `/personnel_requirements/${this.requirementId}`
    const requirementNameField = this.deleteRequirementForm.querySelector('#personnel_requirement_name');
    requirementNameField.textContent = this.requirementName;
  }
}
