import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['addRequirementForm', 'editRequirementForm']

  connect() {
    this.addRequirementForm = this.addRequirementFormTarget;
    this.editRequirementForm = this.editRequirementFormTarget;
  }

  showAddRequirementForm(e) {
    const dataset = e.currentTarget.dataset;

    const person = JSON.parse(dataset.person);
    const personnelRequirementOptions = JSON.parse(dataset.personnelRequirements);
    const personRoleName = dataset.personRoleName;
    const ownerSelectOptions = JSON.parse(dataset.ownerSelectOptions);

    const personIdField = this.addRequirementForm.querySelector('#person_personnel_requirement_person_id')
    const accountIdField = this.addRequirementForm.querySelector('#person_personnel_requirement_account_id')
    const personnelRoleIdField = this.addRequirementForm.querySelector('#person_personnel_requirement_personnel_role_id')
    const personRoleNameField = this.addRequirementForm.querySelector('#person_current_role_name')
    const personNameField = this.addRequirementForm.querySelector('#person_full_name')

    const ownerSelect = this.addRequirementForm.querySelector('#person_personnel_requirement_user_id')
    ownerSelect.options.length = ownerSelectOptions.length;
    ownerSelectOptions.forEach((option, index) => {
      const text = option[0];
      const value = option[1];
      ownerSelect.options[index] = new Option(text, value);
    });

    personIdField.value = person.id;
    accountIdField.value = person.account_id;
    personnelRoleIdField.value = person.personnel_role_id;
    personRoleNameField.textContent = personRoleName;
    personNameField.textContent = `${person.first_name} ${person.last_name}`

    const requirementSelect = this.addRequirementForm.querySelector("#person_personnel_requirement_personnel_requirement_id");
    requirementSelect.options.length = personnelRequirementOptions.length;
    personnelRequirementOptions.forEach((option, index) => {
      const text = option[0];
      const value = option[1];
      requirementSelect.options[index] = new Option(text, value);
    });
  }

  showEditRequirementForm(e) {
    const dataset = e.currentTarget.dataset;

    const requirement = JSON.parse(dataset.requirement);
    const personRoleName = dataset.personRoleName;
    const credentialName = dataset.credentialName;
    const personFullName = dataset.personFullName;
    const ownerSelectOptions = JSON.parse(dataset.ownerSelectOptions);

    this.editRequirementForm.action = `person_personnel_requirements/${requirement.id}`;

    const personIdField = this.editRequirementForm.querySelector('#person_personnel_requirement_person_id')
    const personRoleNameField = this.editRequirementForm.querySelector('#person_current_role_name')
    const credentialNameField = this.editRequirementForm.querySelector('#credential_name')
    const dismissalNoteField = this.editRequirementForm.querySelector('#person_personnel_requirement_dismissal_note')
    const dueDateField = this.editRequirementForm.querySelector('#person_personnel_requirement_due_date');
    const linkToDocumentsField = this.editRequirementForm.querySelector('#person_personnel_requirement_link_to_documents');
    const licenseNumberField = this.editRequirementForm.querySelector('#person_personnel_requirement_license_number');
    const dismissalReasonSelect = this.editRequirementForm.querySelector('#person_personnel_requirement_dismissal_reason');
    const dismissalNotField = this.editRequirementForm.querySelector('#person_personnel_requirement_dismissal_note');
    const memoField = this.editRequirementForm.querySelector('#person_personnel_requirement_memo');
    const personNameField = this.editRequirementForm.querySelector('#person_full_name');

    const ownerSelect = this.editRequirementForm.querySelector('#person_personnel_requirement_user_id')
    ownerSelect.options.length = ownerSelectOptions.length;

    ownerSelectOptions.forEach((option, index) => {
      const text = option[0];
      const value = option[1];
      ownerSelect.options[index] = new Option(text, value);
    });

    personNameField.textContent = personFullName;
    personRoleNameField.textContent = personRoleName;
    credentialNameField.textContent = credentialName;
    personIdField.value = requirement.person_id;
    dismissalNoteField.value = requirement.dismissal_note;
    dueDateField.value = requirement.due_date;
    linkToDocumentsField.value = requirement.link_to_documents;
    licenseNumberField.value = requirement.license_number;
    dismissalNotField.value = requirement.dismissal_note;
    memoField.value = requirement.memo;

    dismissalReasonSelect.value = requirement.dismissal_reason || "";
    ownerSelect.value = requirement.user_id || "";

    const statusRadios = this.editRequirementForm.querySelectorAll("input[name='person_personnel_requirement[status]']");
    [...statusRadios].forEach(el => {
      if (requirement.status == el.value) {
        el.checked = true;
      }
    });
  }
}
