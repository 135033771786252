import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="office-search-form"
export default class extends Controller {
  static targets = ["searchBtn", "searchInput"];

  connect() {
    this.searchInput = this.searchInputTarget;
    this.searchButton = this.searchBtnTarget;
    this.searchButton.disabled = true;
  }

  onInputChange() {
    if (this.searchInput.value.length == 0) {
      this.searchButton.disabled = true
    } else {
      this.searchButton.disabled = false
    }
  }

  onSubmit() {
    if (this.searchInput.value.length == 0) {
      return;
    }

    this.element.requestSubmit()
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 200)
  }
}
