import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onRowClick(e) {
    const row = this.element;
    const link = row.dataset.link;
    const openInNewTab = row.dataset.openInNewTab;
    if (openInNewTab) {
      window.open(link, '_blank');
      return;
    }
    Turbo.visit(link);
  }
}
