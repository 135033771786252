import { Application } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete";
import CheckboxSelectAll from "stimulus-checkbox-select-all";
import Sortable from "stimulus-sortable";

const application = Application.start()
application.register("autocomplete", Autocomplete);
application.register("checkbox-select-all", CheckboxSelectAll);
application.register("sortable", Sortable);

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
