import { Controller } from "@hotwired/stimulus"
import { Offcanvas } from "bootstrap";

// Connects to data-controller="historical-logs-task-edit-form"
export default class extends Controller {
  static targets = ["taskRow", "taskEditForm", "editOffcanvas", "csrfHiddenField"];

  connect() {
    this.offcanvas = new Offcanvas(this.editOffcanvasTarget);

    this.editOffcanvasTarget.addEventListener('hidden.bs.offcanvas', () => {
      this.clearOffcanvasContent(this.editOffcanvasTarget);
    });
  }

  taskRowClick(e) {
    const viewDetailsLinkTarget = e.currentTarget.querySelector('a[data-view-details="viewDetailsLink"]');
    if (e.target == viewDetailsLinkTarget) return;

    const taskRow = e.currentTarget;
    const task = JSON.parse(taskRow.dataset.task);

    this.updateOffcanvasContent(task);
    this.offcanvas.show();
  };

  updateOffcanvasContent(task) {
    const {
      status,
      office_id: officeId,
      user_id: userId,
      id: taskId
    } = task;

    const csrfToken = document.head.querySelector("[name='csrf-token']").content;
    this.csrfHiddenFieldTarget.value = csrfToken;

    const form = this.taskEditFormTarget;
    form.action = `/tasks/${taskId}`;

    const taskOffCanvas = this.editOffcanvasTarget;

    taskOffCanvas.querySelector(`input[data-status="${!status ? 'pending' : status}"]`).checked = true;

    if (taskOffCanvas.querySelector(`input[data-office-id="${officeId}"]`)) {
      taskOffCanvas.querySelector(`input[data-office-id="${officeId}"]`).checked = true;
    }

    if (taskOffCanvas.querySelector(`input[data-user-id="${userId}"]`)) {
      taskOffCanvas.querySelector(`input[data-user-id="${userId}"]`).checked = true;
    }
  };

  clearOffcanvasContent(offcanvas) {
    const statusRadios = offcanvas.querySelectorAll("input[data-status]");
    const officeRadios = offcanvas.querySelectorAll("input[data-office-id]");
    const userRadios = offcanvas.querySelectorAll("input[data-user-id]");

    [...userRadios].forEach(el => {
      el.checked = false;
    });

    [...officeRadios].forEach(el => {
      el.checked = false;
    });
  }
}
