import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="office-dropdown-filter"
export default class extends Controller {
  static targets = ["dropdownSelect"];

  connect() {
    this.preselectOption();
  }

  filter(event) {
    const selected = event.target.value;
    const currentUrl = new URL(window.location.href);

    if (selected != 'All') {
      currentUrl.searchParams.set('office_ids[]', selected);
    } else {
      const searchParams = new URLSearchParams(currentUrl.search);
      searchParams.delete('office_ids[]')

      const [allOption, ...remained] = event.target.options;
      const allOptionValues = [...remained].map(option => option.value);
      allOptionValues.forEach(value => {
        searchParams.append('office_ids[]', value)
      });

      currentUrl.search = searchParams.toString();
    }

    window.location.href = currentUrl.toString();
  }

  preselectOption() {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionValue = this.dropdownSelectTarget.dataset.officeSelectedOption;
    const sessionOfficeIds = JSON.parse(this.dropdownSelectTarget.dataset.officeSelectedOption);

    const officeIds = urlParams.getAll('office_ids[]');

    if (officeIds?.length == 1) {
      this.dropdownSelectTarget.value = officeIds[0];
    } else if (sessionOfficeIds?.length == 1) {
      this.dropdownSelectTarget.value = sessionOfficeIds[0];
    } else {
      this.dropdownSelectTarget.value = 'All';
    }
  }
}

